import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SVGSeparator from '../components/project-blocks/separator'
import InformationSectionBlock from '../components/project-blocks/information-section'

import { informationSectionData } from '../data/project-data--ysdn2019-teaser'

import ysdnHeroImage from '../motion/ysdnHeroImage.mp4'

const HeaderVideo = styled.div`
	width: 100%;
	height: auto;

	padding: 56.25% 0 0 0;
	position: relative;
	margin-bottom: 12px;

	& > iframe,
	& > video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`

const HeadingGroup = styled.header`
	display: grid;
	grid-gap: 6px;
`

const Title = styled.h1`
	font-size: 24px;
	font-weight: 500;
	letter-spacing: 1px;

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
		font-size: 32px;
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const Subtitle = styled.h2`
	font-size: 18px;
	opacity: 0.75;

	@media (min-width: 768px) {
		font-size: 21px;
	}
`

const YsdnTeaser = () => (
	<Layout>
		<SEO title="YSDN 2019 Grad Show Splash Page" />
		<HeaderVideo>
			<video autoPlay muted>
				<source src={ysdnHeroImage} type="video/mp4" />
				Sorry, your browser doesn&apos;t support embedded videos.
			</video>
		</HeaderVideo>
		<HeadingGroup>
			<Title>YSDN 2019 Teaser</Title>
			<Subtitle>Grad Show Splash Page</Subtitle>
		</HeadingGroup>
		<SVGSeparator />
		<InformationSectionBlock data={informationSectionData} />
		<SVGSeparator />
		<Link to="/">← Back</Link>
	</Layout>
)

export default YsdnTeaser
